import React from "react";
import Layout from "../components/Layout";
import RightColumn from "../components/RightColumn";
import { PrivacyPolicy } from "@tyson-foods/react-component-library";
import { Link } from "gatsby";

export default class PrivacyPolicyPage extends React.Component {
  render() {
    return (
      <Layout sidebarImageUrl="/images/photo22.jpg" sidebarImageAlt="" metaTitle="Privacy Policy">
        <div id="mainContent">
            <div id="panel">
                <div className="pt-4"/>
                    <PrivacyPolicy
                    className="pt-5 px-2"
                    aboutOurAdsLink={<Link to="/about-our-ads/">HERE</Link>}
                    />
            </div>
        </div>
        <RightColumn />
      </Layout>
    );
  }
}